// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/collections/provider.ts"
);
import.meta.hot.lastModified = "1715264889093.368";
}
// REMIX HMR END

import gql from 'graphql-tag'
import {
  CollectionListOptions,
  CollectionsQuery,
} from '~storefront/generated/graphql'
import { QueryOptions, sdk } from '~storefront/graphqlWrapper'

export function getCollections(
  options?: CollectionListOptions,
  queryOptions?: QueryOptions,
): Promise<CollectionsQuery> {
  return sdk.collections({ options }, queryOptions)
}

gql`
  query collections($options: CollectionListOptions) {
    collections(options: $options) {
      items {
        id
        name
        description
        slug
        parent {
          name
        }
        featuredAsset {
          id
          preview
        }
        customFields {
          pricingLowest
          pricingInterval
          pricingUseCalculated
        }
      }
    }
  }
`

gql`
  query collection($slug: String, $id: ID) {
    collection(slug: $slug, id: $id) {
      id
      name
      description
      slug
      featuredAsset {
        id
        preview
      }
      breadcrumbs {
        id
        name
        slug
      }
      children {
        id
        name
        description
        slug
        featuredAsset {
          id
          preview
        }
        customFields {
          pricingLowest
          pricingInterval
          pricingUseCalculated
        }
      }
      productVariants {
        items {
          price
          priceWithTax
          currencyCode
        }
        totalItems
      }
      customFields {
        pricingLowest
        pricingInterval
        pricingUseCalculated
      }
    }
  }
`
